













































































































































































.scales-settings-visibility {
  padding: 5px 10px 5px 0;
  cursor: pointer;
}
.scales-settings-name .not-editable{
  margin-top:5px;
}
